<template>
  <no-ssr>
    <a class="actBar" v-if="isShow && currentPath != '/introduce/company'"
       :href="PAY_COMPANY_NEW"
       target="_blank"
    >
      <div class="inner_center">
        <i class="iconfont icon-cha" @click.stop.prevent="close" />
      </div>
    </a>
  </no-ssr>
</template>

<script>
import {fnLocalStorage} from "@/fn";
import {MODEL_ACT_618} from "@/constant/MODELS";
import {ACT_618, PAY_COMPANY_NEW} from "@/constant/_Public/Path";
import {dataSign, dataSign2, dataSign3} from "@/pages/act/618/module";

export default {
  name:"actBar",
  data(){
    return{
      isShow:false,
      ACT_618,
      actLink:ACT_618,
      currentPath: '',
      PAY_COMPANY_NEW
    }
  },
  mounted() {
    this.checkCurrentDateInDataSign();
    this.init();
    this.currentPath = this.$route.path
  },
  methods:{
    init(){
      let act618 = fnLocalStorage.getItemDate(MODEL_ACT_618)
      this.isShow = act618 >= 2 ? false: true;
    },
    close(){
      this.isShow = false;
      let act618 = fnLocalStorage.getItemDate(MODEL_ACT_618)
      if (act618 == undefined){
        fnLocalStorage.setItemDate(MODEL_ACT_618,1)
      }else{
        act618  += 1;
        fnLocalStorage.setItemDate(MODEL_ACT_618,act618)
      }
      this.$emit("close")
    },

    checkCurrentDateInDataSign() {
      const today = new Date();
      const month = today.getMonth() + 1; // 注意月份从0开始，所以加1
      const day = today.getDate();
      const currentDateStr = `${month}.${day}`;

      console.log({currentDateStr})

      if (dataSign.some(item => item.date == currentDateStr)){
        this.actLink = "/act/618";
        console.log(11111)
      }else if (dataSign2.some(item => item.date == currentDateStr)){
        this.actLink = "/act/618/2";
        console.log(222222)
      }else if (dataSign3.some(item => item.date == currentDateStr)){
        this.actLink = "/act/618/3";
        console.log(33333)
      }
    }
  }
}

</script>

<style scoped lang="scss">

.actBar{
  width: 100%;
  height: 60px;
  display: block;
  transition: all 0.8s ease;
  //@include BgImage2(100%,60px,"act/618/actBar.png", 1);
  @include BgImage(100%, 60px, 'act/guoqing/ActGuoQingBar88.png', 1);
  vertical-align: middle;

  .inner_center{
    height: 100%;
    position: relative;
  }

  .icon-cha {
    width: 24px;
    height: 24px;
    background: rgba(0, 0, 0, 0.2);
    @include Position(1, 2, 0, 50%);
    transform: translateY(-50%);
    color: #FFFFFF;
    line-height: 24px;
    text-align: center;
    cursor: pointer;
    border-radius: 50%;
    font-size: 14px;
    @include ElementHover('', rgba(0, 0, 0, 0.3))
  }
}
</style>
