import {toolDate} from "@/tools";

export const dataSign = [
  {
    date: "5.20"
  },
  {
    date: "5.21"
  },
  {
    date: "5.22"
  },
  {
    date: "5.23"
  },
  {
    date: "5.24"
  },
  {
    date: "5.25"
  },
  {
    date: "5.26"
  },
  {
    date: "5.27"
  },
  {
    date: "5.28"
  },
  {
    date: "5.29"
  },
  {
    date: "5.30"
  },
  {
    date: "5.31"
  },
  {
    date: "6.1"
  },
  {
    date: "6.2"
  },

]
export const dataSign2 = [
  {
    date: "6.3"
  },
  {
    date: "6.4"
  },
  {
    date: "6.5"
  },
  {
    date: "6.6"
  },
  {
    date: "6.7"
  },
  {
    date: "6.8"
  },
  {
    date: "6.9"
  },
  {
    date: "6.10"
  },
  {
    date: "6.11"
  },
  {
    date: "6.12"
  },
  {
    date: "6.13"
  },
  {
    date: "6.14"
  },
  {
    date: "6.15"
  },
  {
    date: "6.16"
  },

]
export const dataSign3= [
  {
    date: "6.17"
  },
  {
    date: "6.18"
  },
  {
    date: "6.19"
  },
  {
    date: "6.20"
  },
  {
    date: "6.21"
  },
  {
    date: "6.22"
  },
  {
    date: "6.23"
  },
  {
    date: "6.24"
  },
  {
    date: "6.25"
  },
  {
    date: "6.26"
  },
  {
    date: "6.27"
  },
  {
    date: "6.28"
  },
  {
    date: "6.29"
  },
  {
    date: "6.30"
  },

]


export const coupon = [
  {
    id: 44,
    day: 1,
    state: -1
  },
  {
    id: 45,
    day: 3,
    state: -1
  },
  {
    id: 46,
    day: 7,
    state: -1
  },
  {
    id: 47,
    day: 12,
    state: -1
  }
]

export function disposeSign618(res,dataSignData= dataSign){
  const {status,data} = res;
  if(status == 200){
    // data.list['2024-05-20'].is_sign = true
    // data.list['2024-05-31'].is_sign = true
    // data.list['2024-06-01'].is_sign = true

    const dates = Object.keys(data.list);
    const signIndexArr = dates.map((date, index) => {
      return data.list[date].is_sign ? index : -1;
    }).filter(index => index !== -1);

    /* 获取当天日期，是否签到 */
    const {month,day} = toolDate.getDate('');
    const currDate = `${month}.${day}`;
    const currDateIndex = dataSignData.findIndex(item => item.date == currDate );
    const {user_coupons = {}} = data
    if (user_coupons != undefined || user_coupons != null){

      // 遍历 coupon 数组
      coupon.forEach(couponItem => {
        // 检查当前 coupon 的 id 是否存在于 user_coupons 中
        if (user_coupons.hasOwnProperty(couponItem.id.toString())) {
          // 如果存在，将对应的值赋给 coupon 的 state 属性
          couponItem.state = parseInt(user_coupons[couponItem.id]);
        }
      });
    }

    console.log(coupon)



    return {
      signIndexArr,
      currDate,
      currDateIndex,
      currSign: signIndexArr.indexOf(currDateIndex) > -1,
      batch:data.batch,
      coupon
    };
  }
}
